<template>
    <div id="createSchedule">
        <div class="create_schedule">
            <div class="upperSection">
            <mds-layout-grid class="create_schedule">
                <mds-row>
                    <span>
                        <router-link
                            :to="{
                                name: getRouteDetails(
                                    '/data-dissemination/schedules'
                                ),
                            }"
                        >
                            Scheduled Deliveries
                        </router-link>
                    </span>
                    <span class="create_schedule_router">
                        / {{ scheduleHeaderTitle }}
                    </span>
                </mds-row>
                <mds-row class="create_schedule_heading">
                    {{ scheduleHeaderTitle }}
                </mds-row>
                <mds-row>
                    <mds-col :cols="6">
                        <mds-row class="create_schedule_delivery_type_heading"
                            >Delivery Type</mds-row
                        >
                        <mds-row>
                            <span>
                                <mds-form>
                                    <mds-fieldset variation="radio-group">
                                        <!-- Dynamic and Static Radio Button -->
                                        <span>
                                            <mds-radio-button
                                                class="radioButtonGroup"
                                                name="group"
                                                v-for="deliveryTypes in getDeliveryType"
                                                :key="deliveryTypes.id"
                                                v-model="switchDeliveryType"
                                                :value="deliveryTypes.id"
                                                :label="deliveryTypes.lable"
                                                @input="
                                                    switchDeliveryTypes(
                                                        deliveryTypes
                                                    )
                                                "
                                            ></mds-radio-button>
                                        </span>
                                    </mds-fieldset>
                                </mds-form>
                            </span>
                        </mds-row>
                    </mds-col>
                    <mds-col :cols="6">
                        <mds-row>
                            <mds-col :cols="8" class="data_feed_type">
                                <mds-form>
                                    <mds-select
                                        label="Data Feed"
                                        v-model="selecteddatafeed"
                                        :disabled="isDataFeedDisable"
                                        @change="
                                            changeDataFeed(selecteddatafeed)
                                        "
                                        placeholder="Select a data feed"
                                    >
                                        <option
                                            v-for="item in dropDownDataFeedsList"
                                            :key="item.id"
                                            :value="item.id"
                                            :selected="
                                                parseInt(item.id) ===
                                                dataFeedName
                                            "
                                        >
                                            {{ item.name }}
                                        </option>
                                    </mds-select>
                                </mds-form>
                            </mds-col>

                            <mds-col :col="2" class="new_data_feed_button">
                                <button-component
                                    v-if="permissions.includes('/data-dissemination/data-feeds')"
                                    :onClick="dataFeedButton"
                                    buttonName="New Data Feed"
                                    buttonVariation="flat"
                                    iconName="plus"
                                >
                                </button-component>
                            </mds-col>
                        </mds-row>
                    </mds-col>
                </mds-row>
            </mds-layout-grid>

            <mds-layout-grid class="create_schedule_middle_section">
                <mds-row>
                    <!-- delivery details section -->
                    <mds-col :cols="6">
                        <div class="create_schedule_border"></div>
                        <div class="scheduleSubTitle">Delivery Details</div>
                        <mds-layout-grid>
                            <!-- partner Section -->
                            <mds-row class="create_schedule_selection_section">
                                <mds-col :cols="8" class="data_feed_type">
                                    <mds-form>
                                        <mds-select
                                            label="Partner"
                                            v-model="selectedpartner"
                                            :disabled="isPartnerDisable"
                                            @change="changePartner"
                                            placeholder="Select a partner"
                                        >
                                            <option
                                                v-for="item in dropDownPartnersList"
                                                :key="item.id"
                                                :value="item.id"
                                                :selected="
                                                    item.name === partnerName
                                                "
                                            >
                                                {{ item.name }}
                                            </option>
                                        </mds-select>
                                    </mds-form>
                                </mds-col>

                                <mds-col :col="4" class="new_data_feed_button">
                                    <button-component
                                        v-if="permissions.includes('/data-dissemination/partners')"
                                        :onClick="partnerButton"
                                        buttonName="New Partner"
                                        buttonVariation="flat"
                                        iconName="plus"
                                    >
                                    </button-component>
                                </mds-col>
                            </mds-row>
                            <!-- Delivery Method Section-->
                            <mds-row class="create_schedule_selection_section">
                                <mds-col :cols="8" class="data_feed_type">
                                    <mds-form>
                                        <mds-combo-box
                                            :key="deliveryMethodComboBoxkey"
                                            label="Delivery Method"
                                            placeholder="Select a delivery method"
                                            :disabled="isDeliveryMethodDisable"
                                            :data-set="
                                                dropDownDeliveryMethodsList
                                            "
                                            v-model="selectComboValue"
                                            multiple
                                            :multipleItemLimit="2"
                                        ></mds-combo-box>
                                    </mds-form>
                                </mds-col>
                            </mds-row>
                            <!-- File Format section -->
                            <mds-row class="create_schedule_selection_section">
                                <mds-col :cols="8" class="data_feed_type">
                                    <mds-form>
                                        <!-- if data feed doesn't support velo template -->
                                        <mds-select
                                            v-if="this.isvelotemplate === false"
                                            label="File Format"
                                            v-model="selectedfileformat"
                                            :disabled="!isFormatFileDisable"
                                            @change="changeFileFormat"
                                            placeholder="Select a file format"
                                        >
                                            <option
                                                v-for="item in getFileFormatData"
                                                :key="item.id"
                                                :value="item.id"
                                                :selected="item.id === fileFormatSchedule"
                                            >
                                                {{ item.name }}
                                            </option>
                                         </mds-select>

                                         <!-- if data feed support velo template -->
                                        <mds-select
                                            v-if="this.isvelotemplate === true"
                                            label="File Format"
                                            v-model="selectedfileformat"
                                            :disabled="!isFormatFileDisable"
                                            @change="changeFileFormat"
                                            placeholder="Select a file format"
                                        >
                                            <option
                                                v-for="item in getFileFormatDataForVelotemplate"
                                                :key="item.id"
                                                :value="item.id"
                                                :selected="item.id === fileFormatSchedule"
                                            >
                                                {{ item.name }}
                                            </option>
                                        </mds-select>
                                    </mds-form>
                                </mds-col>
                            </mds-row>
                        </mds-layout-grid>
                    </mds-col>

                    <!-- Schedule Section -->
                    <mds-col :cols="6">
                        <div class="create_schedule_border"></div>
                        <div class="scheduleSubTitle">Schedule</div>
                        <mds-layout-grid>
                            <mds-row class="create_schedule_selection_section">
                                <mds-col :cols="8" class="data_feed_type">
                                    <mds-form>
                                        <mds-date-picker
                                            v-model="selectedDate"
                                                :value="startDate"
                                                :min-max-dates="minMaxDates"
                                                label="Start Date"
                                                placeholder="Select a Date"
                                                :date-format="{locale:'en-gb'}"
                                                @input="changeStartDate(selectedDate)"
                                            ></mds-date-picker>
                                    </mds-form>
                                </mds-col> </mds-row
                        ></mds-layout-grid>

                        <!-- Recurrence Section -->
                        <h4 class="recurrence_title">Recurrence (Time in UTC)</h4>
                        <div id="cron-generator" class="cron-builder"></div>

                        <div class="add-schedulebtn">
                            <mds-button
                                id="cron-result"
                                class="cron-result"
                                variation="secondary"
                                size="small"
                                type="button"
                                :disabled="!addScheduleDisableButton"
                                @click="generateCron()"
                            >
                                Add Schedule
                            </mds-button>
                        </div>
                        <div class="create_schedule_cron_experssion">
                            <div
                                v-for="(item, idx) in multipleCronUi"
                                :key="idx"
                                class="cron-exp"
                            >
                                <div>{{ item }}</div>
                                <div @click="deleteJob(item)">
                                    <mds-icon name="trash" size="small"></mds-icon>
                                </div>
                            </div>
                        </div>
                    </mds-col>
                </mds-row>
            </mds-layout-grid>
            </div>
            <!-- Footer Section -->
            <div class="commonFooterSection">
                <common-footer
                    buttonName="Save"
                    @cancelButton="cancelButton"
                    @saveButton="saveButton"
                    :isDisabled="!saveButtonDisable"
                >
                </common-footer>
            </div>
        </div>
        <loader-component v-if="showLoader"></loader-component>
    </div>
</template>

<script>
import { MdsLayoutGrid, MdsRow, MdsCol } from "@mds/layout-grid";
import MdsForm from "@mds/form";
import MdsFieldset from "@mds/fieldset";
import MdsRadioButton from "@mds/radio-button";
import cronstrue from "cronstrue";
import store from "@/store";
import ButtonComponent from "../../../ui_component/ButtonComponent.vue";
import LoaderComponent from "../../../ui_component/loaderComponent.vue";
import MdsSelect from "@mds/select";
import { MdsButton } from "@mds/button";
import { DATADISSEMINATION_MUTATIONS } from "@/store/modules/dataDissemination/contants";
import CommonFooter from "../components/commonFooter.vue";
import MdsComboBox from "@mds/combo-box";
import MdsIcon from '@mds/icon'
import MdsDatePicker from "@mds/date-picker";

export default {
    name: "create-schedule",
    beforeCreate() {
        this.$store = store;
    },
    components: {
        MdsLayoutGrid,
        MdsRow,
        MdsForm,
        MdsFieldset,
        MdsRadioButton,
        MdsCol,
        ButtonComponent,
        LoaderComponent,
        MdsSelect,
        MdsButton,
        CommonFooter,
        MdsComboBox,
        MdsIcon,
        MdsDatePicker,
    },
    data() {
        return {
            showLoader: false,
            switchDeliveryType: 1,
            toggleCancelButton: false,
            selecteddatafeed: "",
            isDataFeedDisable: false,
            selectedpartner: "",
            isPartnerDisable: false,
            isDeliveryMethodDisable: true,
            selecteddeliverymethod: "",
            selectedfileformat: "",
            selectedDate: "",
            todaysDate: "",
            schedule: "",
            startDate: "",
            cronResult: [],
            viewScheduleButton: false,
            dataFeedName: 0,
            partnerName: "",
            fileFormatName: "",
            selectComboValue: [],
            deliveryMethodComboBoxkey: 0,
            multipleCron: [],
            multipleCronUi: [],
            isvelotemplate: false,
            fileFormatSchedule: 0,
            minMaxDates: {
            min: new Date(),
            max: new Date(2049, 11, 31),
        },
        disableDates: () => false
        };
    },

    props: {
        getDeliveryType: {
            type: Array,
            default: () => [],
        },
        dropDownDataFeedsList: {
            type: Array,
            default: () => [],
        },
        dropDownPartnersList: {
            type: Array,
            default: () => [],
        },
        dropDownDeliveryMethodsList: {
            type: Array,
            default: () => [],
        },
        getFileFormatData: {
            type: Array,
            default: () => [],
        },
        getFileFormatDataForVelotemplate:{
            type: Array,
            default: () => [],
        },
        scheduleHeaderTitle: {
            type: String,
            default: "",
        },
        partnerId: {
            type: String,
            default: "",
        },
        showSelectedTimeSchedule: {
            type: Array,
            default: () => [],
        },
        getEditScheduleData: {
            type: Object,
            default: () => {},
        },
        onClickDisableSave:{
            type: Boolean,
            default:false,
        }
    },

    async mounted() {
        // code for today's date
        let response = new Date().toLocaleDateString();
        let temp = response.split("/");
        if (temp[0].length == 1) {
            temp[0] = "0" + temp[0];
        }
        if (temp[1].length == 1) {
            temp[1] = "0" + temp[1];
        }
        this.todaysDate = temp[2] + "," + temp[0] + "," + temp[1];
        this.minMaxDates= {
            min: new Date(this.todaysDate),
            max: new Date(2049, 11, 31),
        },
        this.cronGenerator();
        this.onEditBindData();
    },

    computed: {
        permissions() {
            return this.$store.state.auth.permissions;
        },
        saveButtonDisable() {
            return (
                (this.selecteddatafeed &&
                    this.selectedpartner &&
                    this.selectComboValue.length &&
                    this.selectedfileformat &&
                    this.selectedDate &&
                    this.viewScheduleButton && this.onClickDisableSave) ||
                (this.dataFeedName &&
                    this.partnerName &&
                    this.selectComboValue.length &&
                    this.fileFormatName &&
                    this.selectedDate &&
                    this.viewScheduleButton && this.onClickDisableSave)
            );
        },
        addScheduleDisableButton(){
            return this.selecteddatafeed
        },
        
        isFormatFileDisable(){
            return this.selecteddatafeed
        }

    },

    methods: {
        onEditBindData() {
            if (Object.keys(this.getEditScheduleData).length) {
                const {partnername, feedid, startdate, schedule, format, isvelotemplate, partnerid } =
                    this.getEditScheduleData;
                this.isDeliveryMethodDisable = false;
                this.isDataFeedDisable = true;
                this.isPartnerDisable = true;
                this.viewScheduleButton = true;
                this.dataFeedName = feedid;
                this.isvelotemplate = isvelotemplate;
                this.selecteddatafeed = feedid;
                this.selectedpartner = partnerid.toString().toUpperCase();
                this.partnerName = partnername;
                this.selectComboValue = this.getEditScheduleData.deliverymethods;
                this.selectedfileformat = format;
                this.fileFormatSchedule = format;
                if (this.selectedfileformat == 1) {
                    this.fileFormatName = "Excel";
                } else {
                    this.fileFormatName = "CSV";
                }
                let date = startdate;
                // const convertMultipleCronUi = schedule.map(x => (cronstrue.toString(x)))
                // this.multipleCronUi = convertMultipleCronUi;
                const scheduleJob = schedule.split('|');
                scheduleJob.forEach(schdeuleItem => {
                    this.multipleCronUi.push(cronstrue.toString(schdeuleItem, { use24HourTimeFormat: true }));
                    this.multipleCron.push(schdeuleItem.trim());
                })
                // this.multipleCronUi.push(cronstrue.toString(schedule));
                // this.multipleCron.push(schedule.trim());
                this.$store.commit(
                    DATADISSEMINATION_MUTATIONS.SET_CRON_EXPRESSION,
                    this.multipleCron
                );
                let res = date.split("T");
                let temp1 = res[0].split("-");
                let todayDate = temp1[0] + "," + temp1[1] + "," + temp1[2];
                this.selectedDate = new Date(todayDate);
                this.startDate = new Date(todayDate);
                this.deliveryMethodComboBoxkey += 1;
            }
        },
        deleteJob(e) {
            const currentIndex = this.multipleCronUi.indexOf(e);
            this.multipleCronUi.splice(currentIndex, 1);
            this.multipleCron.splice(currentIndex, 1);
            this.$store.commit(
                    DATADISSEMINATION_MUTATIONS.SET_CRON_EXPRESSION,
                    this.multipleCron
            );
        },
        cronGenerator() {
            window.$("#cron-generator").cronBuilder();
        },
        generateCron() {
            this.viewScheduleButton = true;
            const expression = window
                .$("#cron-generator")
                .data("cronBuilder")
                .getExpression();
            this.multipleCron.push(expression.substring(1).trim());
            this.multipleCronUi.push(cronstrue.toString(expression, { use24HourTimeFormat: true }));
            this.$store.commit(
                DATADISSEMINATION_MUTATIONS.SET_CRON_EXPRESSION,
                this.multipleCron
            );
            if (this.isvelotemplate == false && this.multipleCronUi.length == 2 ) {
                this.multipleCron.splice(0,1);
                this.multipleCronUi.splice(0,1);
                this.$store.commit(
                    DATADISSEMINATION_MUTATIONS.SET_CRON_EXPRESSION,
                    this.multipleCron
            );
            }
        },

        getRouteDetails(url) {
            const routeDetails = this.$router.resolve({
                path: url,
            });
            return routeDetails.resolved.name;
        },
        switchDeliveryTypes(type) {
            this.$emit("switchDeliveryTypes", type);
        },
        changeDataFeed(val) {
            let res = this.dropDownDataFeedsList.filter((c) => {
                return c.id === val;
            });
            this.isvelotemplate = res[0].isvelotemplate;
            this.multipleCronUi = [];
            this.$emit("editDataFeedData", this.selecteddatafeed);
        },
        changePartner() {
            this.showLoader = true;
            if (this.selectedpartner == 0) {
                this.isDeliveryMethodDisable = true;
                this.selecteddeliverymethod = "";
            } else {
                this.isDeliveryMethodDisable = false;
                this.selectComboValue=[];
                this.$emit("editPartnerData", this.selectedpartner);
                this.showLoader = false;
            }
        },
        changeFileFormat() {
            this.$emit("editFileFormatData", this.selectedfileformat);
        },
        changeStartDate(date) {
            this.$emit("editStartDate", date);
        },
        dataFeedButton() {
            this.$router.push({ name: "Data Dissemination Create Data Feed" });
        },
        partnerButton() {
            this.$router.push({ name: "Data Dissemination Create Partner" });
        },

        saveButton() {
            this.onClickDisableSave = false;
            if (Object.keys(this.getEditScheduleData).length) {
                this.$emit("updateSchedule", this.selectComboValue);
            } else {
                this.$emit("saveSchedule", this.selectComboValue);
            }
        },
        cancelButton() {
            this.toggleCancelButton = !this.toggleCancelButton;
            this.$router.push('/data-dissemination/schedules');
        },
    },
};
</script>

<style lang="scss" scoped>
@import "../../../../assets/css/styles.scss";
.create_schedule {
    padding: 8px 16px 0 16px;
}
.recurrence_title {
    margin-bottom: 6px;
}
.cron-builder p {
    margin: 8px 0;
}
.cron-result,
.cron-exp {
    margin-top: 4px;
    display: flex;
    justify-content: space-between;
    width: 61%;
    cursor: pointer;
}
.create_schedule_heading {
    margin-top: $mds-space-2-x;
    margin-bottom: $mds-space-2-x;
    left: 16px;
    top: 50px;
    font-style: normal;
    font-weight: 250;
    font-size: 32px;
    line-height: 36px;
}

.create_schedule_delivery_type_heading {
    width: 200px;
    font-size: 16px;
    font-weight: bold;
    line-height: 21px;
}

.create_schedule_selection_section {
    margin-top: $mds-space-2-x;
}

.new_data_feed_button {
    margin-top: 30px;
}

.radioButtonGroup {
    display: inline-block;
    width: 150px;
    padding: 0px;
}

.create_schedule_border {
    margin-top: $mds-space-1-x;
    margin-bottom: $mds-space-1-x;
    border-bottom: 2px solid $mds-interactive-color-secondary-active;
}
.add-schedulebtn {
    width: 170px;
}
// .create_schedule_middle_section {
//     height: 440px;
// }
.cron-builder {
    margin-bottom: 14px;
}
.create_schedule_cron_experssion {
    margin-top: 12px;
    overflow-y: auto;
    max-height: 150px;
}
.cron-exp {
    margin-top: 4px;
}
.scheduleSubTitle{
    font-weight: 600;
}
.upperSection{
    min-height: 83vh;
}
</style>
