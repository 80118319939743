<template>
    <div>
        <CreateSchedules
            v-if="checkScheduleEdit"
            :scheduleHeaderTitle="scheduleHeaderTitle"
            :getDeliveryType="getDeliveryType"
            :dropDownDataFeedsList="dropDownDataFeedsList"
            :dropDownPartnersList="dropDownPartnersList"
            :dropDownDeliveryMethodsList="dropDownDeliveryMethodsList"
            :getFileFormatData="getFileFormatData"
            :getFileFormatDataForVelotemplate="getFileFormatDataForVelotemplate"
            :getEditScheduleData="getEditScheduleData"
            :partnerId="partnerId"
            :onClickDisableSave="onClickDisableSave"
            @editPartnerData="editPartnerData"
            @editDataFeedData="editDataFeedData"
            @editStartDate="editStartDate"
            @editFileFormatData="editFileFormatData"
            @saveSchedule="saveSchedule"
            @updateSchedule="updateSchedule"
            :key="newCreateScheduleKey"
        >
        </CreateSchedules>
        <notification-component
            v-if="showNotification"
            :notificationMessage="notificationMessage"
            :notificationType="notificationType"
            :keyType="notificationKey"
            :dismissDelay="5000"
            @close="showNotification = false"
        ></notification-component>

        <loader-component v-if="showLoader"></loader-component>
    </div>
</template>

<script>
import get from "lodash/get";
import { mapGetters, mapState } from "vuex";
import store from "@/store";
import CreateSchedules from "../components/CreateSchedules.vue";
import { SCHEDULE_ACTIONS } from "@/store/modules/schedule/contants";
import LoaderComponent from "../../../ui_component/loaderComponent.vue";
import NotificationComponent from "../../../ui_component/notificationComponent.vue";

export default {
    beforeCreate() {
        this.$store = store;
    },
    components: {
        CreateSchedules,
        LoaderComponent,
        NotificationComponent,
    },
    data() {
        return {
            scheduleHeaderTitle: "Create Scheduled Delivery",
            showLoader: true,
            showNotification: false,
            notificationMessage: "",
            notificationType: "",
            getDeliveryType: [
                {
                    id: 1,
                    lable: "Data Feed",
                },
                // {
                //   id: 2,
                //   lable: "Report",
                // },
            ],
            getFileFormatData: [
                {
                    id: 1,
                    name: "Excel",
                },
                {
                    id: 0,
                    name: "CSV",
                },
            ],
            getFileFormatDataForVelotemplate: [
                {
                    id: 0,
                    name: "CSV",
                },
            ],
            // save button
            scheduleFeedId: 0,
            feedId: 0,
            vendorId: "",
            deliveryMethodId: [],
            fileType: null,
            isCompressed: false,
            schedule: "",
            startDate: "",
            getEditScheduleData: {},
            partnerId: "",
            newCreateScheduleKey: 0,
            onClickDisableSave: true,
        };
    },
    async mounted() {
        await this.$store.dispatch(SCHEDULE_ACTIONS.FETCH_FILTER_PARTNER_LIST);
        await this.$store.dispatch(
            SCHEDULE_ACTIONS.FETCH_DROPDOWN_DATAFEED_LIST
        );
        const ScheduledFeedId = await this.$route.query.ScheduledFeedId;
        this.scheduleFeedId = ScheduledFeedId;
        if (ScheduledFeedId != undefined) {
            this.showLoader = true;
            var data = {
                ScheduledFeedId: ScheduledFeedId,
            };
            await this.$store.dispatch(
                SCHEDULE_ACTIONS.FETCH_EDIT_SCHEDULE_LIST,
                {
                    data,
                }
            );
            const response = this.scheduleEdit;

            this.vendorId = response.partnerid;
            await this.$store.dispatch(
                SCHEDULE_ACTIONS.FETCH_DROPDOWN_DELIVERYMETHOD_LIST,
                { val: response.partnerid }
            );

            this.scheduleHeaderTitle = "Edit Schedule: " + response.feedname;
            this.getEditScheduleData = response;
            this.feedId = response.feedid;
            this.startDate = response.startdate;
            this.fileType = response.format;
        }
        this.newCreateScheduleKey += 1;
        this.showLoader = false;
    },

    computed: {
        ...mapGetters(["cachedDispatch"]),
        ...mapState({
            dropDownDataFeedsList(state) {
                return get(
                    state,
                    "schedule.dropDownDataFeeds.dropDownDataFeeds",
                    []
                );
            },
            setCronExpression(state) {
                return get(state, "dataDissemination.setCronExpression", null);
            },
            dropDownPartnersList(state) {
                return get(state, "schedule.filterPartners.filterPartners", []);
            },
            dropDownDeliveryMethodsList(state) {
                return get(
                    state,
                    "schedule.dropDownDeliveryMethods.dropDownDeliveryMethods",
                    []
                );
            },

            scheduleSave(state) {
                return get(state, "schedule.saveSchedules.saveSchedules", []);
            },
            scheduleSaveError(state) {
                return get(state, "schedule.saveSchedules.__error__", null);
            },
            scheduleEdit(state) {
                return get(state, "schedule.editSchedules.editSchedules", []);
            },
        }),
        checkScheduleEdit() {
            if (this.$route.query.ScheduledFeedId) {
                return Object.keys(this.getEditScheduleData).length;
            } else {
                return true;
            }
        },
    },
    methods: {
        // select partner from dropdown
        async editPartnerData(val) {
            this.showLoader = true;
            this.vendorId = val;
            await this.$store.dispatch(
                SCHEDULE_ACTIONS.FETCH_DROPDOWN_DELIVERYMETHOD_LIST,
                { val }
            );
            this.showLoader = false;
        },

        // select data-feed from dropdown
        editDataFeedData(val) {
            this.feedId = parseInt(val);
        },

        // select date
        editStartDate(date) {
            this.startDate = date;
        },

        // select file format from dropdown
        editFileFormatData(val) {
            this.fileType = val;
        },

        // save schedule
        async saveSchedule(val) {
            for (let i = 0; i < val.length; i++) {
                this.deliveryMethodId[i] = parseInt(val[i]);
            }
            let data = {
                feedId: this.feedId,
                vendorId: this.vendorId,
                deliveryMethodIds: this.deliveryMethodId,
                fileType: this.fileType,
                isCompressed: this.isCompressed,
                schedule: this.setCronExpression,
                startDate: this.startDate.toLocaleDateString(),
            };
            await this.$store.dispatch(
                SCHEDULE_ACTIONS.FETCH_SAVE_SCHEDULE_LIST,
                {
                    data,
                }
            );
            let response = this.scheduleSave;
            const obj = {
                response,
                ntfnMsg: "The schedule has been created.",
                ntfnType: "success",
                ntfnKey: "success-default",
                ntfnfailureMsg: this.scheduleSaveError,
            };
            this.notificationResponse(obj);
        },

        // update schedule
        async updateSchedule(val) {
            for (let i = 0; i < val.length; i++) {
                this.deliveryMethodId[i] = parseInt(val[i]);
            }
            if (this.scheduleFeedId != undefined) {
                let data = {
                    scheduleFeedId: this.scheduleFeedId,
                    feedId: this.feedId,
                    vendorId: this.vendorId,
                    deliveryMethodIds: this.deliveryMethodId,
                    fileType: this.fileType,
                    isCompressed: this.isCompressed,
                    schedule: this.setCronExpression,
                    startDate: this.startDate.toLocaleDateString(),
                };
                await this.$store.dispatch(
                    SCHEDULE_ACTIONS.FETCH_SAVE_SCHEDULE_LIST,
                    {
                        data,
                    }
                );
                let response = this.scheduleSave;
                const obj = {
                    response,
                    ntfnMsg: "The schedule has been updated.",
                    ntfnType: "success",
                    ntfnKey: "success-default",
                    ntfnfailureMsg: this.scheduleSaveError,
                };
                this.notificationResponse(obj);
            }
        },


        // common notification event
        async notificationResponse({
            response,
            ntfnMsg,
            ntfnType,
            ntfnKey,
            ntfnfailureMsg,
        }) {
            if (response.statuscode == 201) {
                this.notificationMessage = ntfnMsg;
                this.notificationType = ntfnType;
                this.showNotification = true;
                this.notificationKey = ntfnKey;
                this.showLoader = false;
                this.onClickDisableSave = false;
                setTimeout(() => {
                    this.$router.push({
                        name: "Data Dissemination Schedule",
                    });
                }, 2000);
            } else {
                this.showLoader = false;
                this.notificationKey = "error-default";
                this.notificationMessage = ntfnfailureMsg;
                this.notificationType = "error";
                this.showNotification = true;
                this.onClickDisableSave = true;
            }
        },

    },
};
</script>
